import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Button } from 'react-bootstrap';

import '../../generic-page.scss';

const CSPVPforEvoPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Evolved-001 Playbook</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/category_evo.png"
            alt="Evolved-001 Playbook"
          />
        </div>
        <div className="page-details">
          <h1>Evolved-001 Playbook</h1>
          <h2>A guide for Evolved-001 teams in PVP.</h2>
        </div>
      </div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          This guide is meant to introduce Evolved-001 (E1) comps and how the
          team plays, as well as note some common members of the comp and the
          roles they serve.
        </p>
        <p>
          This isn’t meant to be a definitive guide to playing E1 so much as it
          is a rundown of what to expect and some common decisions you’ll be
          pressed to make over the course of a typical match (I am not nearly as
          credible as other RTA challengers!).
        </p>
        <p>
          Much of the information presented here comes from my own experience
          learning E1, which involved soliciting the very helpful discord
          community, watching way too many RTA streams, and losing a lot.{' '}
        </p>
        <p>
          I hope this guide helps make E1 comps more approachable for the 10
          other people that pulled her banner, as well aid those trying to
          understand the thought process behind E1 so they may better play
          against it.{' '}
        </p>
        <p>
          If you have any questions or feedback, please don't be afraid to dm
          Yiggins who is the author of the guide <strong>Yiggles#8990</strong>.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
        <SectionHeader title="Evolved-001 Playbook" />
        <p>
          Due to the guide being massive, very detailed and also to allow the
          author to easily edit it, it will be kept in the original form as a
          google doc.
        </p>
        <p>Link to the guide:</p>
        <Button
          variant="primary"
          href="https://docs.google.com/document/d/1j5DyHPH3IAMIWkmhrgetf5ClJG1GNcDNGPhNUHiv3oU/edit"
          target="_blank"
          rel="noreferrer"
        >
          Evolved-001 Playbook
        </Button>
      </div>
    </DashboardLayout>
  );
};

export default CSPVPforEvoPage;

export const Head: React.FC = () => (
  <Seo
    title="Evolved-001 Playbook | Counter Side | Prydwen Institute"
    description="A guide for Evolved-001 teams in PVP."
  />
);
